import React from "react"
import {graphql} from "gatsby"
import Seo from "../components/molecules/Seo";
import LinkCard from "../components/molecules/LinkCard";
import Container from "../components/atoms/Container";
import Pagination from "../components/organisms/Pagination";
import {convertToSlug} from "../utils/helpers";
import Header from "../components/molecules/Header";
import styled from "styled-components";
import SemiCircle from "../components/atoms/SemiCircle";
import HrHeading from "../components/atoms/HrHeading";
import ColorScheme from "../components/atoms/ColorScheme";

const Holder = styled.div`
  h1 {
    margin: 2rem 0;
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin: 4rem 0;
    }
  }
`;

const Links = styled.div`
  > div:last-child .semi-circle{
    display: none;
  }
  > div:nth-child(odd) {
    .semi-circle path {
      fill: ${props => props.theme.secondary};
    }
  }
  > div:nth-child(even) {
    .button {
      background-color: ${props => props.theme.secondary};
    }
  }
`;

function TagArchive({data, pageContext}) {
  const links = data.allPrismicLink.nodes;
  return (
    <ColorScheme primary="yellow" secondary="teal">
      <Holder>
        <Seo title={pageContext.tag} />
        <Header title="Tag Archive"/>
        <HrHeading><h1>{pageContext.tag}</h1></HrHeading>
        <Container>
          <Links>
            {links && links.map((link, i) => <div key={link.id}>
              <LinkCard linkContent={link} />
              <SemiCircle rotate={i % 2 === 1} />
            </div>)}
          </Links>
        </Container>
        <Pagination totalPages={pageContext.numPages} currentPage={pageContext.currentPage}
                    baseSlug={`${convertToSlug(pageContext.tag)}/`} />
      </Holder>
    </ColorScheme>
  )
}

export default TagArchive

export const pageQuery = graphql`
  query tagArchiveQuery($skip: Int!, $limit: Int!, $tag: String!) {
    allPrismicLink(
      filter: {tags: {eq: $tag}}
      sort: {fields: first_publication_date, order: ASC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        tags
        data {
          title {
            text
          }
          text {
            raw
          }
          link {
            url
          }
          layout
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
          author {
            text
          }
        }
      }
    }
  }
`